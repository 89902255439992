<template>
  <div class="quickly-send flex flex-direction-column">
    <van-swipe
      ref="swipeRef"
      class="swiper-container flex-1"
      :duration="0"
      :touchable="false"
      :show-indicators="false"
      :initial-swipe="activeTabBar"
    >
      <van-swipe-item
        v-for="(item, index) in tabBarList"
        :key="index"
        class="swiper-item flex flex-direction-column"
        :item-id="String(index)"
        @touchmove.stop="() => false"
      >
        <div class="component flex-1">
          <TaskListView
            v-if="item.key === 'task' && loadedTabBar[index]"
            :ref="`componentRef_${index}`"
            @tabChange="
              () => {
                if (noClick) {
                  onChange(index)
                }
              }
            "
            @showModal="modalVisible = true"
          />
          <StrategyListView
            v-if="item.key === 'strategy' && loadedTabBar[index]"
            :ref="`componentRef_${index}`"
            @tabChange="
              () => {
                if (noClick) {
                  onChange(index)
                }
              }
            "
          />
          <MaterialLibrary
            v-if="item.key === 'material' && loadedTabBar[index]"
            :ref="`componentRef_${index}`"
          />
        </div>
      </van-swipe-item>
    </van-swipe>
    <TabBarView
      :activeTabBar="activeTabBar"
      :tabBarList="tabBarList"
      @onChange="(index) => onChange(index, 'click')"
    />
    <ModalView v-model="modalVisible" maskFun @confirm="onNotShouldSend">
      <div class="cl29 fw500 ptb32 tc">本次不该发送给客户?</div>
    </ModalView>
  </div>
</template>

<script>
import TabBarView from './tabBar.vue'
import TaskListView from './task/component.vue'
import StrategyListView from './strategy/component.vue'
import MaterialLibrary from '@/views/material/materialLibrary/component.vue'
import ModalView from '@/components/modalView/index.vue'

const tabBarList = [
  { key: 'task', name: '任务' },
  { key: 'strategy', name: '客户打法' },
  { key: 'material', name: '素材库' }
  //   { key: 'task', name: '任务' },
  //   { key: 'strategy', name: '策略' },
  //   { key: 'material', name: '素材库' }
]
export default {
  name: 'quickly-send',
  components: {
    TabBarView,
    TaskListView,
    StrategyListView,
    MaterialLibrary,
    ModalView
  },
  data() {
    return {
      activeTabBar: 0, // 选中的tabBar页面下标
      tabBarList, // tabBar枚举值
      loadedTabBar: [], // 已挂载的tabBar页面 (解决该页面挂载时,把所有tabBar页面同时挂载)
      modalVisible: false, // 弹窗显示
      // isReady: false // 是否完成页面初次渲染
      noClick: true // 判断是否点击了tabBar
    }
  },
  mounted() {
    console.log('页面挂载')
    // uni.hideHomeButton()
    // uni.setNavigationBarTitle({
    // 	title: this.tabBarList[this.activeTabBar]['name']
    // })
    // 挂载选中的页面
    this.loadedTabBar = this.tabBarList.map((item, index) =>
      index === this.activeTabBar ? item : null
    )
  },
  activated() {
    this.onActivated()
  },
  // beforeRouteLeave(to, from, next) {
  //   to.meta.keepAlive = false
  //   next()
  // },
  watch: {
    activeTabBar: {
      handler(newval = 0) {
        if (this.$refs.swipeRef) this.$refs.swipeRef.swipeTo(newval)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // tabBar改变事件
    onChange(i, val) {
      try {
        let index = 0
        if (val === 'click') {
          this.noClick = false
          index = i
        } else {
          index = i + 1
        }
        if (this.activeTabBar === index) return false
        const data = this.tabBarList[index]
        if (!this.loadedTabBar[index]) {
          this.loadedTabBar[index] = data
        }
        this.activeTabBar = index
        // uni.setNavigationBarTitle({
        // 	title: this.tabBarList[index]['name']
        // })
        console.log('切换tab', this.activeTabBar)
        // this.onActivated()
      } catch (error) {
        console.log(error)
      }
    },
    // 让子组件刷新页面
    onActivated() {
      console.log('onActivated', this.$refs)
      const contentRef = this.$refs[`componentRef_${this.activeTabBar}`]
      if (contentRef) {
        if (Array.isArray(contentRef)) {
          if (
            this.$refs[`componentRef_${this.activeTabBar}`][0] &&
            this.$refs[`componentRef_${this.activeTabBar}`][0].activated
          ) {
            this.$refs[`componentRef_${this.activeTabBar}`][0].activated()
          }
        } else {
          if (this.$refs[`componentRef_${this.activeTabBar}`].activated) {
            this.$refs[`componentRef_${this.activeTabBar}`].activated()
          }
        }
      }
    },
    // 任务不该发送
    onNotShouldSend() {
      const contentRef = this.$refs[`componentRef_${this.activeTabBar}`]
      if (contentRef) {
        if (Array.isArray(contentRef)) {
          if (
            this.$refs[`componentRef_${this.activeTabBar}`][0] &&
            this.$refs[`componentRef_${this.activeTabBar}`][0].onNotShouldSend
          ) {
            this.$refs[`componentRef_${this.activeTabBar}`][0].onNotShouldSend()
          }
        } else {
          if (this.$refs[`componentRef_${this.activeTabBar}`].onNotShouldSend) {
            this.$refs[`componentRef_${this.activeTabBar}`].onNotShouldSend()
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ptb32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.quickly-send {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.swiper-item {
  height: 100%;
}
.component {
  overflow: hidden;
}
</style>
