<template>
  <div class="container fs14">
    <Skeleton v-show="pageLoading" />
    <!-- <div
      v-show="!pageLoading"
      class="container flex flex-direction-column relative overflow-y-scroll"
    > -->
    <!-- <scroll-view
				class="scroll-container" 
				scroll-y
				refresher-enabled
				:lower-threshold="150"
				:refresher-triggered="isTriggered" 
				@refresherrefresh="refresherrefresh"
				@scrolltolower="getDataSource('load')"
			> -->
    <div
      v-show="!pageLoading"
      ref="scrollRef"
      class="scroll-view overflow-y-scroll"
      @scroll="onScroll"
    >
      <van-pull-refresh
        v-model="isTriggered"
        class="flex flex-direction-column relative"
        @refresh="onRefresh"
      >
        <van-list
          v-if="dataSource && dataSource.length"
          v-model="isLoading"
          :finished="isFinished"
          :immediate-check="false"
          @load="getDataSource('load')"
        >
          <div class="plr15" :style="{ 'min-height': contentHeight + 'px' }">
            <div
              v-for="(item, index) in dataSource"
              :key="index"
              class="task-item mt12"
            >
              <TaskItem
                :dataSource="item"
                :qw_external_userid="qw_external_userid"
                :qw_chat_id="qw_chat_id"
                @onRefresh="onRefresh"
                @showModal="showModal"
              />
            </div>
            <div class="list-bootom-hint fs12 cl99 tc">
              {{
                isLoading
                  ? '努力加载中~'
                  : isFinished
                  ? '- 别滑了，咱们都是有底线的 -'
                  : ''
              }}
            </div>
          </div>
        </van-list>
        <div
          v-else
          class="empty-data flex flex-direction-column flex-justify-center flex-align-center fs12 cl99 tc"
          :style="{ 'min-height': contentHeight + 'px' }"
        >
          <img
            src="@/assets/img/suggestion/task_nodata.svg"
            class="img block"
          />
          暂无任务
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import TaskItem from './taskItem.vue'
import Skeleton from './skeleton.vue'
import { getMarketingTaskList } from '@/service/quickSend.service.js'
import {
  getContext,
  getCurExternalContact,
  getCurExternalChat
} from '@/utils/nwechat.js'
import { taskRefused } from '@/service/task.service.js'
import { Toast } from 'vant'
import { debounce } from '@/utils/index'

export default {
  components: { TaskItem, Skeleton },
  data() {
    return {
      dataSource: [], // 任务列表
      qw_external_userid: '', // 外部联系人userid (聊天工具栏才有)
      qw_chat_id: '', // 当前客户群的群ID (聊天工具栏才有)
      isLoading: false, // 列表加载状态
      isFinished: false, // 列表加载完成
      selectedData: {}, // 选中的任务数据
      scrollTop: 0, // 滑动距离
      isTriggered: false, // 列表上拉刷新
      contentHeight: 500, // 内容区域高度
      pageLoading: true // 骨架屏加载
    }
  },
  async mounted() {
    await this.getEntryType()
    await this.getDataSource('first', 'publish')
    if (this.pageLoading) this.pageLoading = false
    this.$nextTick(() => {
      this.contentHeight = this.$refs.scrollRef.offsetHeight
    })
  },
  watch: {
    dataSource(newval) {
      console.log('任务列表', newval)
    }
  },
  methods: {
    activated() {
      if (this.isFinished) this.isFinished = false
      this.getDataSource('onShow')
      this.$nextTick(() => {
        this.$refs.scrollRef.scrollTop = this.scrollTop
        console.log('this.$refs.scrollRef.scrollTop', this.scrollTop)
      })
    },
    // 获取任务列表 key 'first': 首次加载, 'onShow': 激活, 'load': 加载更多
    async getDataSource(key, type) {
      if ((this.isFinished || this.isLoading) && key !== 'first') return false
      try {
        const {
          dataSource,
          qw_external_userid,
          qw_chat_id,
          isLoading,
          isFinished,
          isTriggered
        } = this
        const userInfo = JSON.parse(localStorage.getItem('vuex')) || {}
        const body = {
          page: 1,
          page_size: 10
          //   member_userid: 'LiBin'
        }

        if (userInfo.user.info.qw_userid)
          body.member_userid = userInfo.user.info.qw_userid
        if (qw_external_userid) body.customer_userid = qw_external_userid
        if (qw_chat_id) body.chat_id = qw_chat_id
        if (key === 'load')
          body.page = Math.ceil(dataSource.length / body.page_size) + 1
        if (key === 'onShow') {
          body.page = 1
          body.page_size = dataSource.length
        }
        this.isLoading = true
        // if (key === 'first' && !pageLoading) uni.showLoading({title: '加载中', mask: true})
        const { data, code } = await getMarketingTaskList(body)
        // if (key === 'first' && !pageLoading) uni.hideLoading()
        this.isLoading = false
        if (isTriggered) {
          setTimeout(() => (this.isTriggered = false), 300)
        }
        if (code == 200) {
          if (key === 'first' && !data.list.length && type === 'publish') {
            console.log('tasktabChange')
            this.$emit('tabChange')
          }
          if (!data.list || data.list.length < body.page_size) {
            this.isFinished = true
          }
          if (key === 'load') {
            this.dataSource = [...dataSource, ...data.list]
          } else {
            this.dataSource = [...data.list]
          }
        }
      } catch (error) {
        this.isLoading = false
        this.isFinished = true
        if (this.isTriggered) this.isTriggered = false
        console.log(error)
      }
    },
    // 获取页面入口类型
    async getEntryType() {
      //   this.qw_external_userid = 'wm1DICCQAACZXG3uiEZ26TJiT3xHEBcA'
      const entry = await getContext()
      if (entry === 'single_chat_tools') {
        this.qw_external_userid = await getCurExternalContact()
        this.qw_chat_id = ''
      } else if (entry === 'group_chat_tools') {
        this.qw_external_userid = ''
        this.qw_chat_id = await getCurExternalChat()
      }
    },
    // 刷新列表
    onRefresh() {
      this.isFinished = false
      this.isLoading = false
      this.getDataSource('first')
    },
    showModal(data = {}) {
      this.selectedData = data
      this.$emit('showModal')
    },
    // 不应该发送
    async onNotShouldSend() {
      const { selectedData, qw_external_userid, qw_chat_id } = this
      const { task_id } = selectedData
      const { code } = await taskRefused({
        task_id,
        type: '2',
        userid: qw_external_userid || qw_chat_id
      })

      if (code == 200) {
        Toast.success('操作成功!')
        this.onRefresh()
      }
    },
    // 监听滚动事件
    onScroll(event) {
      console.log('滚动时间', event.target.scrollTop)
      this.scrollTop = event.target.scrollTop
    }
    // refresherrefresh() {
    //   // this.isTriggered = true
    //   this.onRefresh()
    // }
  }
}
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}

.container {
  .scroll-view {
    height: 100%;
  }
  .list-bootom-hint {
    height: 44px;
    line-height: 44px;
  }
  .empty-data {
    width: 100%;
    height: 100%;

    .img {
      width: 200px;
      height: 132px;
    }
  }
}
</style>
