import { $ajaxService } from './request'
import { service } from './request'

/**
 * 任务列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/hxe1lx/edit?toc_node_uuid=miE9LYqnXeJ47uy9#P8ltl
 * @param  {} member_userid 成员id
 * @param  {} status 任务状态，execute-待执行，end-已结束，no_start-未开始
 * @param  {} page
 * @param  {} page_size
 */
export const getTaskList = ({ member_userid, status, page, page_size }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/work_task_list', {
    member_userid,
    status,
    page,
    page_size
  })

// 引导教程页实时数据
export const getGuidePage = ({ send_record_id, page, page_size, task_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/statistical_data', {
    send_record_id,
    page,
    page_size,
    task_id
  })

/**
 * 不该发任务 https://we-customer.w.eolink.com/home/api_studio/inside/api/detail?apiID=48802838&groupID=1978494&projectHashKey=XIQlMVI1213a36bc20d06967488fe804e9f801782c717ec&spaceKey=we-customer
 * @param  {} refused_id 原因id
 * @param  {} task_id 任务id
 * @param  {} userid 客户id 拒绝单个客户必传
 * @param  {} type 1拒绝任务 2拒绝单个客户
 * @param  {} refused_name 原因id 为空自定义原因必传
 */
export const taskRefused = (body) =>
  $ajaxService.post($ajaxService.baseUrl + 'guide/task_refused', body)
