<template>
  <div class="tab-bar-container flex flex-justify-around bgf">
    <div
      v-for="(item, index) in tabBarList"
      :key="index"
      class="tab-bar-item flex-1 flex flex-justify-center"
      @click="onChange(index)"
    >
      <div
        class="flex flex-direction-column flex-align-center flex-justify-center"
      >
        <img
          v-show="activeTabBar === index"
          class="tab-bar-icon"
          :src="require(`@/assets/img/quickSend/${item.key}-active.svg`)"
        />
        <img
          v-show="activeTabBar !== index"
          class="tab-bar-icon"
          :src="require(`@/assets/img/quickSend/${item.key}.svg`)"
        />
        <div class="fs12 fw400 lh17 cl69 mt6">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeTabBar: [Number],
    tabBarList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    onChange(index) {
      this.$emit('onChange', index)
    }
  }
}
</script>

<style lang="less" scoped>
.mt6 {
  margin-top: 6px;
}
.tab-bar-container {
  border-top: 1px solid #e5e5ea;

  .tab-bar-item {
    padding-top: 8px;
    padding-bottom: calc(4px + env(safe-area-inset-bottom) / 2);
  }
  .item-box {
    width: 100%;
  }
  .tab-bar-icon {
    width: 24px;
    height: 24px;
  }
}
</style>
