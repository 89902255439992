<template>
  <div class="task-item-container bgf bdr4 pt12 plr15" @click="onDetail(false)">
    <div class="fs16 fw500 mb9">{{ dataSource.name }}</div>
    <div class="fs12 cl9c flex flex-align-center mb8">
      <span class="mr4">距离任务结束剩余</span>
      <span v-if="countDownData.day" class="clth mr4">
        {{ countDownData.day }}
      </span>
      <span v-if="countDownData.day">天</span>
      <span v-if="countDownData.hour" class="clth ml4 mr4">
        {{ countDownData.hour }}
      </span>
      <span v-if="countDownData.hour">时</span>
      <span v-if="countDownData.minute" class="clth ml4 mr4">
        {{ countDownData.minute }}
      </span>
      <span v-if="countDownData.minute">分</span>
      <span v-if="countDownData.second" class="clth ml4 mr4">
        {{ countDownData.second }}
      </span>
      <span v-if="countDownData.second">秒</span>
    </div>
    <div v-if="dataSource.content" class="wb mb8 ws">
      {{ dataSource.content }}
    </div>
    <div v-if="dataSource.annex_content_json.length">
      <MediaGride
        pageType="task"
        mediaWidth="48px"
        mediaIconWidth="15px"
        :isShowMediaTitle="false"
        :isPreviewMedia="false"
        :mediaList="dataSource.annex_content_json"
      />
    </div>
    <div class="media-handle flex flex-justify-between flex-align-center">
      <div>
        <div v-if="isEdit" class="lh40 clth fs12" @click.stop="showModal">
          本次不该发给 TA
        </div>
      </div>
      <div class="flex flex-align-center">
        <div
          v-if="isEdit"
          class="handle-box flex flex-align-center fs12 cl9c mr32"
          @click.stop="onDetail(true)"
        >
          <img class="icon mr4" src="@/assets/img/material-library/edit.svg" />
          编辑
        </div>
        <div
          class="handle-box flex-s0 flex flex-align-center fs12 cl9c fw500"
          @click.stop="onSend"
        >
          <img class="icon mr4" src="@/assets/img/material-library/send.svg" />
          发送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaGride from '@/components/detail/mediaGride.vue'
import { sendMonitor, backTaskStatus } from '@/service/quickSend.service.js'
import {
  formatTaskItem,
  formatType,
  formatSendParams
} from '@/utils/sendMessage.js'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { Toast } from 'vant'
import { encodeParams } from '@/utils/router'

export default {
  components: { MediaGride },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    qw_external_userid: {
      // 外部联系人userid (聊天工具栏才有)
      type: String,
      default: () => ''
    },
    qw_chat_id: {
      // 当前客户群的群ID (聊天工具栏才有)
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      countDownData: {
        // 倒计时数据
        day: '0',
        hour: '00',
        minute: '00',
        second: '00'
      },
      times: null // 倒计时定时器
    }
  },
  mounted() {
    if (this.times) clearInterval(this.times)
    this.times = setInterval(() => this.countDown(), 1000)
  },
  destroyed() {
    if (this.times) clearInterval(this.times)
  },
  computed: {
    isEdit() {
      return String(this.dataSource.is_editable) === '1'
    }
  },
  methods: {
    // 前往内容编辑页面
    onDetail(isEdit) {
      const {
        name,
        annex_content_json,
        content,
        id,
        task_id,
        end_time,
        task_send_record_son_id
      } = this.dataSource

      this.$router.push({
        name: 'content-edit',
        query: encodeParams({
          pageType: 'task',
          annex_content_json,
          content,
          id,
          task_id,
          title: name,
          isEdit,
          end_time,
          task_send_record_son_id
        })
      })
    },
    // 计算倒计时时间
    countDown() {
      const { end_time } = this.dataSource
      if (!end_time) return false
      const endTime = end_time.replace(/-/g, '/')
      // const endTime = '2022/08/22 15:39:00'
      var nowTime = Date.now() //当前时间
      var setDate = new Date(endTime)
      var setTime = setDate.getTime() //设定的时间
      if (nowTime >= setTime) {
        this.countDownData = {
          day: '0',
          hour: '00',
          minute: '00',
          second: '00'
        }
        if (this.times) clearInterval(this.times)
        return this.$emit('onRefresh')
      }
      //获取剩余时间总秒数
      var getTimes = (setTime - nowTime) / 1000
      //获取天数
      var d = parseInt(getTimes / 60 / 60 / 24)
      d = d
      //获取小时
      var h = parseInt((getTimes / 60 / 60) % 24)
      h = h < 10 ? '0' + h : h
      //获取分钟
      var m = parseInt((getTimes / 60) % 60)
      m = m < 10 ? '0' + m : m
      //获取秒数
      var s = parseInt(getTimes % 60)
      s = s < 10 ? '0' + s : s
      this.countDownData = {
        day: Number(d) ? d : '',
        hour: Number(d) || Number(h) ? h : '',
        minute: Number(h) || Number(m) ? m : '',
        second: !d && h <= 1 ? s : ''
      }
    },
    // 聊天工具栏发送内容包
    async onSend() {
      try {
        const { qw_external_userid, qw_chat_id, dataSource } = this
        const { annex_content_json, content, task_id } = dataSource
        let status = false
        let contentArr = []
        if (annex_content_json.length)
          contentArr = [...formatTaskItem(annex_content_json)]
        if (content) {
          contentArr.unshift({ type: 'TEXT', content: content })
        }
        Toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        })

        const share_data_id = await this.beforeSend()
        for (let i = 0; i < contentArr.length; i++) {
          let type = formatType(contentArr[i].type)
          let params = await formatSendParams(
            type,
            {
              ...contentArr[i],
              task_id,
              customer_id: qw_external_userid,
              chart_id: qw_chat_id,
              source: 'task'
            },
            share_data_id
          )
          console.log('发送参数:::', type, params)
          const { sendStatus } = await sendChatMessageAllType(
            type,
            params,
            false
          )
          if (sendStatus) status = true
        }
        Toast.clear()
        if (status) {
          await this.sendComplete()
        }
      } catch (error) {
        console.log(error)
        Toast.clear()
      }
    },
    // 发送前的埋点回调
    async beforeSend() {
      const { dataSource, qw_external_userid } = this
      const { task_id } = dataSource

      if (!qw_external_userid) return ''
      const body = {
        payable_id: task_id,
        type: 'task',
        user_id: qw_external_userid
      }

      let { data } = await sendMonitor(body)
      return data?.share_data_id || ''
    },
    async sendComplete() {
      // 回传改变状态-任务
      const { qw_external_userid, qw_chat_id, dataSource } = this
      const { task_send_record_son_id } = dataSource
      const userInfo = JSON.parse(localStorage.getItem('vuex')) || {}
      const { code } = await backTaskStatus({
        send_record_son_id: task_send_record_son_id,
        member_userid: userInfo.user.info.qw_userid,
        external_userid: qw_external_userid || qw_chat_id,
        send_status: 'success'
      })

      if (code == 200) {
        Toast.success('发送成功!')
        setTimeout(() => this.$emit('onRefresh'), 1500)
      }
    },
    // 显示不该发送提示弹窗
    showModal() {
      this.$emit('showModal', this.dataSource)
    }
  }
}
</script>

<style lang="less" scoped>
.icon {
  width: 16px;
  height: 16px;
}
.mb9 {
  margin-bottom: 9px;
}
.ml4 {
  margin-left: 4px;
}
.mr4 {
  margin-right: 4px;
}
.mr32 {
  margin-right: 32px;
}
.lh40 {
  line-height: 40px;
}
.task-item-container {
  .media-handle {
    margin-top: -10px;

    .handle-box {
      height: 40px;
    }
  }
}
</style>
